@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

.carousel-container {
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.uncollapsible {
    white-space: nowrap;
}